/* Estilo general del cuerpo */
body {
  font-family: 'Arial', sans-serif;
  background-color: rgb(18, 17, 31);
  margin: 0;
  padding: 0;
  color: #fff;
}

/* Asegurar que todos los elementos usen border-box para un cálculo de ancho consistente */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Contenedor principal */
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Contenedor del formulario */
.container {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background-color: rgb(37, 36, 50);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(18, 17, 31, 0.2);
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
}

/* Formulario de login */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Inputs del formulario */
.form-input {
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}

.form-input:focus {
  border-color: #fff;
}

.form-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* Botón de submit del formulario */
.submit-button {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: rgb(255, 75, 68);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: rgba(255, 75, 68, 0.7);
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Imagen de fondo en el formulario */
.background-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  pointer-events: none; /* Evita que la imagen sea interactiva */
}

/* Mensaje de error */
.error-message {
  color: #ff4747;
  margin-top: 15px;
  font-size: 14px;
}

/* Botones para descargar CSV y ver gráfico */
.button-group {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.download-button,
.chart-button {
  width: 49%;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button {
  background-color: rgb(255, 75, 68); /* Color rojo */
  color: #fff;
}

.download-button:hover {
  background-color: rgba(255, 75, 68, 0.7);
}

.chart-button {
  background-color: rgb(107, 160, 93); /* Color verde */
  color: #fff;
}

.chart-button:hover {
  background-color: rgba(107, 160, 93, 0.7);
}

/* Estilos para la vista de datos */
.data-container {
  width: 100%;
  background-color: rgb(37, 36, 50);
  color: #fff;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: auto;
}

/* Ajuste del contenedor de la tabla */
.table-container {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Estilos para la tabla */
.data-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

/* Estilos para las celdas */
.data-table th,
.data-table td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
}

/* Estilos para las cabeceras */
.data-table th {
  background-color: rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 0;
  z-index: 1;
  vertical-align: top;
}

/* Estilos para el contenido de la cabecera */
.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Estilos para el botón de ordenamiento en la cabecera */
.sort-button {
  background: none;
  border: 1px solid #fff;
  cursor: pointer;
  font-size: 10px; /* Reducir el tamaño de fuente */
  color: #fff;
  padding: 0;
  border-radius: 50%; /* Hacer el botón circular */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.sort-button:hover {
  border-color: rgb(255, 75, 68);
  color: rgb(255, 75, 68);
}

/* Estilos para los filtros */
.filter-container {
  display: flex;
  flex-direction: column; /* Cambiar a columna para poner elementos en líneas separadas */
  align-items: flex-start; /* Alinear al inicio */
}

.filter-input { /* Nueva clase para inputs de filtro */
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  font-size: 12px;
  margin-top: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  white-space: nowrap;
}

.filter-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.invert-checkbox {
  margin-top: 4px;
  display: flex;
  align-items: center;
  padding: 0; /* Eliminar padding */
}

.invert-checkbox label {
  font-size: 12px;
  color: #fff;
  margin: 0; /* Eliminar márgenes */
  padding: 0; /* Eliminar padding */
  display: flex;
  align-items: center;
}

.invert-checkbox input[type="checkbox"] {
  margin: 0; /* Eliminar márgenes */
  padding: 0; /* Eliminar padding */
  margin-right: 3px; /* Añadir un pequeño margen derecho */
}

/* Estilos para la sección de Preguntas Frecuentes */
.faq-section {
  width: 100%;
  max-width: 800px;
  margin: 10px auto 20px; /* Centrado horizontal con margen superior e inferior */
  padding: 0 20px 20px 20px;
  background-color: rgb(37, 36, 50);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(18, 17, 31, 0.2);
}

.faq-section h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
}

.faq-list {
  max-height: 600px; /* Aumentar la altura máxima si es necesario */
  overflow-y: auto;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  padding: 10px 0;
}

.faq-question:hover {
  color: rgb(255, 75, 68);
}

.faq-answer {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
}

.faq-item svg {
  width: 16px;
  height: 16px;
  color: #fff;
}

/* Estilos para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 17, 31, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Contenido del modal */
.modal-content {
  position: relative;
  width: 90%;
  height: 90%;
  background-color: rgb(37, 36, 50);
  padding: 20px;
  border-radius: 12px;
  overflow: auto;
}

/* Botón de cerrar "X" pequeño y minimalista */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent; /* Fondo transparente */
  border: none;
  color: #fff;
  font-size: 20px; /* Tamaño para una "X" pequeña */
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #ff4b44; /* Cambia el color al pasar el cursor */
}

/* Estilos para el spinner de carga */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Estilos para las celdas que contienen imágenes */
.image-cell {
  padding: 0;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-cell img {
  display: block;
  margin: 0;
  padding: 0;
  width: 29px;
  height: 30px;
}

/* Estilos adicionales */
.data-table tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Estilos para móviles */
@media (max-width: 576px) {
  .container {
    margin-top: 10px; /* Eliminar margen superior para centrar verticalmente */
  }

  .data-table th,
  .data-table td {
    font-size: 10px;
    padding: 6px;
  }

  .filter-input {
    font-size: 10px;
    padding: 4px;
  }

  .download-button,
  .chart-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .faq-section {
    width: calc(100% - 20px); /* 10px de margen a cada lado */
    margin: 20px 10px; /* Margen superior e inferior de 20px, y lateral de 10px */
    padding: 0 15px 15px 15px; /* Reducir padding para optimizar espacio */
    box-shadow: none; /* Opcional: eliminar sombra */
    border-radius: 8px; /* Reducir radio de bordes */
  }

  .faq-section h2 {
    font-size: 20px;
    padding-bottom: 8px;
  }

  .faq-question {
    font-size: 16px;
    padding: 8px 0;
  }

  .faq-answer {
    font-size: 14px;
  }

  .faq-item svg {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 576px) and (orientation: landscape) {
  .container {
    margin-top: 20px; /* Reducir margen superior para mejor visibilidad */
    padding: 20px; /* Reducir padding para adaptarse al espacio */
  }

  .submit-button {
    font-size: 14px;
    padding: 12px 24px;
  }

  .form-input {
    padding: 12px;
    font-size: 14px;
  }
}

/* Estilos para tabletas */
@media (min-width: 577px) and (max-width: 768px) {
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Espacio adicional alrededor del formulario */
    box-sizing: border-box;
    flex-grow: 1; /* Permite que el contenedor ocupe el espacio disponible */
    margin-top: 20px;
  }

  .container {
    margin-top: 200px; /* Eliminar margen superior para centrar verticalmente */
  }
}

@media (min-width: 577px) and (max-width: 768px) and (orientation: landscape) {
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Espacio adicional alrededor del formulario */
    box-sizing: border-box;
    flex-grow: 1; /* Permite que el contenedor ocupe el espacio disponible */
    margin-top: 20px;
  }

  .container {
    margin-top: 200px; /* Eliminar margen superior para centrar verticalmente */
  }
}

/* Estilos para pantallas medianas */
@media (min-width: 769px) and (max-width: 992px) {
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Espacio adicional alrededor del formulario */
    box-sizing: border-box;
    flex-grow: 1; /* Permite que el contenedor ocupe el espacio disponible */
    margin-top: 20px;
  }

  .container {
    margin-top: 0; /* Eliminar margen superior para centrar verticalmente */
  }
}

@media (min-width: 769px) and (max-width: 992px) and (orientation: landscape) {
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Espacio adicional alrededor del formulario */
    box-sizing: border-box;
    flex-grow: 1; /* Permite que el contenedor ocupe el espacio disponible */
    margin-top: 20px;
  }

  .container {
    margin-top: 0; /* Eliminar margen superior para centrar verticalmente */
  }
}

/* Estilos para pantallas grandes */
@media (min-width: 993px) and (max-width: 1200px) {
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Espacio adicional alrededor del formulario */
    box-sizing: border-box;
    flex-grow: 1; /* Permite que el contenedor ocupe el espacio disponible */
    margin-top: 20px;
  }

  .container {
    margin-top: 0; /* Eliminar margen superior para centrar verticalmente */
  }
}

@media (min-width: 993px) and (max-width: 1200px) and (orientation: landscape) {
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Espacio adicional alrededor del formulario */
    box-sizing: border-box;
    flex-grow: 1; /* Permite que el contenedor ocupe el espacio disponible */
    margin-top: 20px;
  }

  .container {
    margin-top: 0; /* Eliminar margen superior para centrar verticalmente */
  }
}

/* Estilos para pantallas extra grandes */
@media (min-width: 1201px) {
  .app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Espacio adicional alrededor del formulario */
    box-sizing: border-box;
    flex-grow: 1; /* Permite que el contenedor ocupe el espacio disponible */
    margin-top: 20px;
  }

  .container {
    margin-top: 100px; /* Eliminar margen superior para centrar verticalmente */
  }
}

@media (min-width: 1201px) and (orientation: landscape) {
  
}
